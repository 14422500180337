import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import {
    Container
} from "../components/global"

const TermsOfServicePage = () => (
    <Layout>
    <SEO title="apocha AGB" />
    <Navigation />
    <TermsOfServiceContainer>
        <h2>Allgemeine Geschäftsbedingungen</h2>
        <h4>1. Allgemeines</h4>
        <h5>1.1 Anwendungsbereich</h5>
        <p>
            apocha mit u.a. den Produkten „Standard“, „Plus“ und „Premium“ (zusammen auch „apocha“) ist ein Angebot der
            apocha UG (haftungsbeschränkt), Im Brachmoos 12, 88149 Nonnenhorn (nachfolgend „apocha UG„ oder „Anbieter“).
            Diese Allgemeinen Geschäftsbedingungen finden für die Nutzung aller Produkte von apocha Anwendung und regeln
            die von der apocha UG zu erbringenden Leistungen sowie die Befugnisse und Pflichten der Kunden dieser
            Angebote („Kunde“).
        </p>
        <h5>1.2 „Standard“</h5>
        <p>
            Das Produkt „Standard“ stellt für die Kunden eine kostenlose Version von apocha zur Verfügung, die die grundlegenden
            Funktionen zum Führen eines Haushaltsbuches enthält, wie z.B. u.a. das Erfassen von Einnahmen und Ausgaben. Das
            Produkt „Standard“ enthält teilweise Beschränkungen z.B. bei der Anzahl der anlegbaren Konten auf 5 und der Anzahl der
            monatlich verfügbaren automatischen Belegerfassungen auf 10. Nicht genutzte automatische Erfassungen verfallen nach einem
            Monat und werden nicht übertragen. apocha behält es sich ausdrücklich vor, diese Beschränkungen zu verändern. Ebenso
            behält apocha sich vor, diese Version durch die Anzeige von Werbung zu finanzieren.
        </p>
        <h5>1.3 „Plus“</h5>
        <p>
            Das Produkt „Plus“ stellt für die Kunden eine kostenlose, werbefreie Version von apocha zur Verfügung, die die grundlegenden
            Funktionen zum Führen eines Haushaltsbuches enthält, wie z.B. u.a. das Erfassen von Einnahmen und Ausgaben. Das
            Produkt „Standard“ enthält teilweise Beschränkungen z.B. bei der Anzahl der anlegbaren Konten auf 50 und der Anzahl der
            monatlich verfügbaren automatischen Belegerfassungen auf 50. Nicht genutzte automatische Erfassungen verfallen nach einem
            Monat und werden nicht übertragen. apocha behält es sich ausdrücklich vor, diese Beschränkungen zu verändern.
        </p>
        <h5>1.4 „Premium“</h5>
        <p>
            Das Produkt „Premium“ stellt für die Kunden eine kostenlose, werbefreie Version von apocha zur Verfügung, die die grundlegenden
            Funktionen zum Führen eines Haushaltsbuches enthält, wie z.B. u.a. das Erfassen von Einnahmen und Ausgaben. Das
            Produkt „Standard“ enthält teilweise Beschränkungen z.B. bei der Anzahl der monatlich verfügbaren automatischen
            Belegerfassungen auf 250. Nicht genutzte automatische Erfassungen verfallen nach einem
            Monat und werden nicht übertragen. apocha behält es sich ausdrücklich vor, diese Beschränkungen zu verändern.
        </p>

        <h4>2. Registrierung</h4>
        <h5>2.1 Angaben des Nutzers</h5>
        <p>
            Die Nutzung von apocha ist erst nach Registrierung sowie Abschluss eines entsprechenden Vertrages möglich.
            Die Einzelheiten zum Vertragsschluss sind in Ziffer 3 geregelt.
            Der Kunde ist verpflichtet, sämtliche Angaben im Rahmen der Registrierung wahrheitsgemäß zu machen.
            Das Kundenkonto wird mit dem angegebenen Benutzernamen und der angegebenen E-Mail-Adresse verknüpft.
            Der Kunde ist selbst dafür verantwortlich, dass er Zugang zu seinem E-Mail-Konto hat und ihm bekannt
            ist, mit welchem Benutzernamen und mit welcher E-Mail-Adresse er sich registriert hat.
            Die apocha UG ist berechtigt, einzelne Registrierungen auch ohne Angabe von Gründen abzulehnen.
        </p>
        <h5>2.2 Kommunikation</h5>
        <p>
            Die personalisierte Vertragsabwicklung, wie die Zusendung von Rechnungen oder Hinweise auf Vertragsänderungen,
            erfolgt über die vom Kunden hinterlegte E-Mail Adresse. Der Kunde ist selbst dafür verantwortlich, sein
            E-Mail-Konto regelmäßig auf E-Mails zu überprüfen. Zusendungen im Rahmen der Vertragskommunikation
            erfolgen nur an E-Mail Adressen, die im Rahmen des Registrierungsprozesses vom Kunden angegeben wurden.
        </p>

        <h4>3. Vertragsschluss und Widerrufsbelehrung</h4>
        <h5>3.1 Abschluss des Vertrags</h5>
        <p>
            Mit Abschluss der Registrierung und dem Anklicken der „Registrieren“-Schaltfläche macht der Kunde der apocha UG
            ein Angebot zum Abschluss eines entsprechenden Vertrages über die Nutzung der kostenlosen Version „Standard“.
            Mit dem Anklicken der „Abonnieren“-Schaltfläche im Nutzerkonto macht der Kunde der apocha UG
            ein Angebot zum Abschluss eines entsprechenden Vertrages über den Erwerb ein kostenpflichtiges apocha Produkts
            im Abonnement.
            Die apocha UG kann dieses Angebot durch Freischaltung des Benutzerkontos des Kunden annehmen. Beim Erwerb eines
            kostenpflichtigen Abonnements der Produkte apocha Plus und apocha Premium erhält der Kunde zusätzlich eine  
            entsprechende Rechnung an die im Kaufprozess angegebene E-Mail-Adresse.<br/>
            <br/>
            Vom Entgelt nicht erfasst sind Kosten, die dem Kunden im Zusammenhang mit der Nutzung von „apocha“ aufgrund von
            Leistungen Dritter entstehen, d.h. insbesondere Kosten für die Nutzung von Endgeräten und die Internetverbindungen.
            Diese Kosten sind vom Kunden selbst zu tragen.
        </p>
        <h5>3.2 Laufzeit des Vertrags</h5>
        <p>
            Wenn nichts Abweichendes geregelt wurde, hat der Vertrag eine Mindestlaufzeit von einem Monat. Danach verlängert
            er sich automatisch um jeweils einen weiteren Monat, wenn er nicht von einer der Parteien gekündigt wird. Für die
            Produkte „Plus“ und „Premium“ werden auch vergünstigt mit einer Laufzeit von einem Jahr angeboten. In diesen Fällen
            hat der Vertrag eine Mindestlaufzeit von einem Jahr. Danach verlängert er sich automatisch um jeweils ein weiteres
            Jahr, wenn er nicht von einer der Parteien gekündigt wird.
        </p>
        <h5>3.3 Kündigung des Vertrags</h5>
        <p>
            Wenn nichts Abweichendes geregelt wurde, ist eine Kündigung jederzeit zum Ablauf der Vertragslaufzeit möglich.
            Die Kündigung kann z.B. per E-Mail unter Angabe der E-Mail-Adresse, auf welche das Abonnement gebucht ist,
            sowie direkt über das Kundenkonto erfolgen. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
            Ein wichtiger Grund liegt insbesondere dann vor, wenn eine fällige Zahlung durch den Kunden nicht erbracht wurde.
        </p>
        <h5>3.4 Widerrufsrecht</h5>
        <p>
            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist
            beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie die apocha UG
            mittels einer eindeutigen Erklärung über Ihren Entschluss, den Vertrag zu widerrufen, informieren. Die kann z.B. mit
            einer E-Mail an <a href="mailto:support@apocha.app">support@apocha.app</a> erfolgen. Es gilt das Absendedatum des Widerrufs.
        </p>
        <h5>3.5 Folgen des Widerrufs</h5>
        <p>
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich
            der Lieferkosten unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung
            über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
            das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes
            vereinbart. In keinem Fall werden Ihnen von der apocha UG wegen dieser Rückzahlung Entgelte berechnet.
        </p>
        <h5>3.6 Preisanpassungen</h5>
        <p>
            Die apocha UG behält sich vor, den Preis für ein Abonnement anzupassen, wenn sich der Produktumfang verändert oder
            sich unsere Betriebskosten erhöhen. Faktoren hierbei können beispielsweise gestiegene Kosten für die Produktion
            unserer Inhalte, für die Aufrechterhaltung des technischen Betriebes unserer Plattformen sowie im Bereich von
            Personal und Infrastruktur (z. B. erhöhte Mietkosten) sein; ebenso aber auch gesteigerte staatliche Abgaben.
            Die apocha UG wird die betroffenen Kunden über eine geplante Anpassung mindestens 8 Wochen im Voraus per E-Mail an
            die hinterlegte E-Mail-Adresse informieren. Sofern der Kunde den Vertrag nicht rechtzeitig vor dem ihm mitgeteilten
            Termin der Preisanpassung kündigt, gilt der mitgeteilte angepasste Preis ab diesem Zeitpunkt als vereinbart.
        </p>
        <h5>3.7 Fälligkeit und Abrechnung</h5>
        <p>
            Das Entgelt für das jeweilige Abonnement ist vorab fällig. Wir behalten uns eine anteilige Abrechnung (in der Regel
            monatlich bzw. jährlich) vor, so dass insbesondere die erste und die letzte Rechnung anteilig erfolgen können. Bei
            einem Wechsel zwischen Abonnements mit unterschiedlicher Laufzeit wird die Gebühr für das neue Abonnement sofort
            fällig. Die Abrechnung erfolgt über einen externen Zahlungsdienstleister, der als Zwischenhändler auftritt.
        </p>
        <h5>3.8 Testzeitraum</h5>
        <p>
            Kunden haben teilweise die Möglichkeit die Produkte „Plus“ und „Premium“ für eine beschränkte Zeit kostenlos zu nutzen.
            Auf die Details und insbesondere die jeweilige Dauer der kostenlosen Testphase wird auf der Webseite und bei der Buchung
            hingewiesen. Die Anzahl der kostenlosen Testphasen ist auf eine pro Kunde begrenzt. Das Angebot gilt nur für Neukunden,
            die noch keine kostenlose Testphase bezogen haben.
        </p>

        <h4>4 Mitwirkungspflichten des Kunden</h4>
        <h5>4.1 Unterstützung</h5>
        <p>
            Der Kunde wird die apocha UG bei der Erbringung der vertraglichen Leistungen in angemessenem Umfang unterstützen.
        </p>
        <h5>4.2 Systemvoraussetzungen</h5>
        <p>
            Für die Nutzung der Software müssen allgemeine und die sich aus der Produktbeschreibung ergebenden
            Systemvoraussetzungen beim Kunden erfüllt sein, insbesondere müssen z.B. ein Internetanschluss oder eine
            mobile Datenverbindung sowie ein internetfähiges Endgerät vorhanden sein.
            Der Kunde trägt hierfür selbst die Verantwortung.
        </p>
        <h5>4.3 Geschützte Inhalte</h5>
        <p>
            Soweit der Kunde der apocha UG geschützte Inhalte überlässt (z.B. Grafiken, Marken und sonstige urheber- oder
            markenrechtlich geschützte Inhalte), räumt er dem Anbieter sämtliche für die Durchführung der vertraglichen
            Vereinbarung erforderlichen Rechte ein. Der Kunde versichert in diesem Zusammenhang, dass er alle erforderlichen
            Rechte an überlassenen Kundenmaterialien besitzt, um dem Anbieter die entsprechenden Rechte einzuräumen.
        </p>
        <h5>4.4 Schutz der Zugangsdaten</h5>
        <p>
            Der Kunde hat die ihm zur Verfügung gestellten Zugangsdaten geheim zu halten und dafür zu sorgen, dass etwaige
            Angehörige des gleichen Haushalts, denen Zugangsdaten zur Verfügung gestellt werden, dies ebenfalls tun. Die Leistung
            der apocha UG darf Dritten außerhalb des eigenen Haushalts nicht zur Verfügung gestellt werden, soweit das nicht
            von den Parteien ausdrücklich vereinbart wurde.
        </p>
        <h5>4.5 Sperrung des Nutzers</h5>
        <p>
            Die apocha UG behält sich das Recht vor, Kunden im Falle von Verstößen gegen die in Ziffern 4.3 und 4.4 aufgeführten
            Regelungen mit sofortiger Wirkung für die Nutzung von „apocha“ zu sperren. Gleiches gilt im Falle eines Zahlungsverzugs.
            Bereits entrichtete Entgelte verfallen in diesem Fall und werden nicht zurückerstattet. Nach Zahlung des Entgelts kann
            die Sperrung wieder aufgehoben werden.
        </p>

        <h4>5 Support</h4>
        <p>
            Ein Supportfall liegt vor, wenn die Software die vertragsgemäßen Funktionen gemäß der Produktbeschreibung nicht erfüllt.
            Meldet der Kunde einen Supportfall, so hat er eine möglichst detaillierte Beschreibung der jeweiligen Funktionsstörung zu
            liefern, um eine möglichst effiziente Fehlerbeseitigung zu ermöglichen.
            Die Meldung erfolgt per E-Mail an <a href="mailto:support@apocha.app">support@apocha.app</a>.
        </p>

        <h4>6 Verfügbarkeit</h4>
        <p>
            Die apocha UG übernimmt keine Garantie und Gewähr dafür, dass apocha den Anforderungen der Kunden entspricht,
            unterbrechungsfrei, sicher oder fehlerfrei ist. Die apocha UG behält sich das Recht vor, 
            einzelne Funktionen – insbesondere aus rechtlichen Gründen – jederzeit aus apocha zu entfernen oder durch
            andere Funktionen zu ersetzen.
        </p>

        <h4>7 Gewährleistung</h4>
        <p>
            Es gelten grundsätzlich die gesetzlichen Regelungen zur Gewährleistung in Mietverträgen. Die §§ 536b BGB (Kenntnis
            des Mieters vom Mangel bei Vertragsschluss oder Annahme), 536c BGB (Während der Mietzeit auftretende Mängel; Mängelanzeige
            durch den Mieter) finden Anwendung. Die Anwendung des § 536a Abs. 2 BGB (Selbstbeseitigungsrecht des Mieters) ist
            jedoch ausgeschlossen. Ausgeschlossen ist auch die Anwendung von § 536a Abs. 1 BGB (Schadensersatzpflicht des Vermieters),
            soweit die Norm eine verschuldensunabhängige Haftung vorsieht.
        </p>

        <h4>8 Haftung und Schadensersatz</h4>
        <p>
            Resultieren Schäden des Kunden aus dem Verlust von Daten, so haftet apocha hierfür nicht, soweit die Schäden durch
            eine regelmäßige und vollständige Sicherung aller relevanten Daten durch den Kunden vermieden worden wären.
            Die Haftung von apocha für Schäden, die aufgrund leichter Fahrlässigkeit verursacht wurden, ist auf wesentliche
            Vertragspflichten, d.h. solche Pflichten, deren Erfüllung die ordnungsgemäße Vertragsdurchführung überhaupt erst
            ermöglicht, deren Nichterfüllung den Vertragszweck gefährden würde und auf deren Einhaltung der Nutzer regelmäßig
            vertraut, beschränkt.
        </p>

        <h4>9 Übertragung der Rechte und Pflichten</h4>
        <p>
            Die Abtretung der Rechte und Pflichten aus diesem Vertrag ist nur mit vorheriger schriftlicher Zustimmung von apocha
            zulässig. apocha ist berechtigt, Dritte mit der Erfüllung der Pflichten aus diesem Vertrag zu betrauen.
        </p>

        <h4>10 Änderung der Geschäftsbedingungen und des Vertrags im Übrigen</h4>
        <p>
            Der Anbieter behält sich die Änderung dieser Allgemeinen Geschäftsbedingungen vor. Bei Änderung wird der Kunde hierüber
            elektronisch informiert. Die geänderte Version der Allgemeinen Geschäftsbedingungen wird Vertragsbestandteil, wenn der
            Kunde nicht innerhalb von 14 Tagen elektronisch oder schriftlich widerspricht. Widerspricht der Kunde den Änderungen
            innerhalb von 14 Tagen nach Mitteilung, behalten die zum Zeitpunkt des Vertragsabschlusses gültigen Allgemeinen
            Geschäftsbedingungen solange Gültigkeit, bis das jeweilige Vertragsverhältnis endet.<br/>
            <br/>
            Die jeweiligen Allgemeinen Nutzungsbedingungen des gewählten Zahlungsdienstleisters finden ergänzende
            Anwendung zu den hier getroffenen Regelungen.
        </p>

        <h4>11 Geltendes Recht und Gerichtsstand</h4>
        <p>
            Es gilt das Recht der Bundesrepublik Deutschland. Die Anwendung des UN-Kaufrechts ist ausgeschlossen. Ist der Kunde
            Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, so ist
            ausschließlicher Gerichtsstand Nonnenhorn.
        </p>

        <h4>12 Salvatorische Klausel</h4>
        <p>
            Sollte eine Bestimmung des Vertrages unwirksam sein oder werden oder der Vertrag unvollständig sein, so bleibt die
            Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt. Die unwirksame Bestimmung gilt durch eine solche Bestimmung
            ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt.
            Gleiches gilt für etwaige Vertragslücken.
        </p>
    </TermsOfServiceContainer>
    <Footer />
  </Layout>
)

export default TermsOfServicePage

const TermsOfServiceContainer = styled(Container)
`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
